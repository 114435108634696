import { buildGraphqlQuery, cleanQuery } from '../../data/utils';

export const SET_PERSONAL_ORDERED_SERVICES_ITEMS =
  'SET_PERSONAL_ORDERED_SERVICES_ITEMS';

export const setPersonalOrderedServicesItems = (orderedServices, query) => ({
  type: SET_PERSONAL_ORDERED_SERVICES_ITEMS,
  payload: {
    response: orderedServices,
    query,
  },
  meta: {
    loading: false,
  },
});

export async function orderedServicesPersonalItemsRequest(fetch, query) {
  const allowedKeys = ['page', 'done'];
  const graphqlQuery = buildGraphqlQuery(cleanQuery(query, allowedKeys));

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        orderedServices${graphqlQuery}{
          next
          prev
          count
          results {
            id
            object_id
            service{
              meta {
                title
              }
              price
              period{
                title
              }
            }
            invoice {
              number
              amount
              payed
              description
              pay_date
              created
            }
            content_object {
              title
              price {
                value, currency
              }
        		}
            done
            date_start
            date_end
            created
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.orderedServices);
}

export function fetchPersonalOrderedServices(fetch, query) {
  return dispatch => {
    orderedServicesPersonalItemsRequest(fetch, query).then(orderedServices => {
      dispatch(setPersonalOrderedServicesItems(orderedServices, query));
    });
  };
}
