import { SET_DEVICE } from 'actions/app/device';

const defaultState = {
  deviceInfo: {},
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case SET_DEVICE:
      return { ...state, deviceInfo: action.payload };
    default:
      return state;
  }
}
