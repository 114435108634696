export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const RESET_PROFILE = 'RESET_PROFILE';

export async function fetchProfile(fetch) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: '{profile{name,username,type,phones{number,description}}}',
    }),
  })
    .then(response => response.json())
    .then(data => data.data.profile);
}

export function setProfile(payload) {
  return {
    type: SET_PROFILE,
    payload,
  };
}

export function resetProfile() {
  return {
    type: RESET_PROFILE,
  };
}

export function getProfile(fetch, success) {
  return dispatch => {
    fetchProfile(fetch).then(profile => {
      if (success) {
        dispatch(success(profile));
      } else {
        dispatch(setProfile(profile));
      }
    });
  };
}
