import { buildGraphqlQuery, cleanQuery } from '../data/utils';

export const ticketsRequest = async (fetch, payload = {}) => {
  const allowedKeys = ['is_active'];
  const cleanedQuery = cleanQuery(payload, allowedKeys);
  const graphqlQuery = buildGraphqlQuery(cleanedQuery);

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        tickets${graphqlQuery} {
          results {
            id
            number
            isNew
            passed
            updated
            mistakesCount
            attempts
            isNewVersion: is_new_version
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.tickets);
};

export const SET_TICKETS = 'SET_TICKETS';

export const setTickets = tickets => ({ type: SET_TICKETS, payload: tickets });

export const ticketRequest = async (fetch, id) =>
  fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        ticket(id: ${id}) {
          id,
          number
          questions {
            id
            question
            image
            answers {
              text
              image
              id
              isCorrect: is_correct
            }
          }
        }
      }
      `,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.ticket);

export const SET_TICKET = 'SET_TICKET';

export const setTicket = payload => ({
  type: SET_TICKET,
  payload,
});

export const SET_ANSWER = 'SET_ANSWER';

export const saveAnswer = (fetch, answer) => () => {
  fetch('/graphql', {
    body: JSON.stringify({
      query: `mutation Mutation($answer:PdrUserAnswerInputType) {
        saveUserAnswer(userAnswer: $answer) {
          question
          answer
          ticket
        }
      }`,
      variables: { answer },
    }),
  }).then(resp => resp.json());
};

export const saveTestAnswer = (fetch, testId, answer) => () => {
  fetch('/graphql', {
    body: JSON.stringify({
      query: `mutation Mutation($answer:PdrUserAnswerInputType) {
        saveTestAnswer(answer: $answer, pdrTest: ${testId}) {
          id
        }
      }`,
      variables: { answer },
    }),
  }).then(resp => resp.json());
};

export const createPdrTest = (fetch, { ticket, isStrict = false }) =>
  fetch('/graphql', {
    body: JSON.stringify({
      query: `
        mutation Mutation($test:PdrTestInputType) {
          startPdrTest(test: $test) {
            id
          }
        },
      `,
      variables: {
        test: { ticket, isStrict },
      },
    }),
  })
    .then(resp => resp.json())
    .then(data => data.data.startPdrTest);

export const pdrTestsRequest = async fetch =>
  fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        pdrTests {
          id
          ticket
          created
          updated
          questions {
            id
          }
          answers {
            id
          }
          finished
          canBeContinued: can_be_continued
          isStrict: is_strict
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.pdrTests);

export const pdrTestRequest = async (fetch, testId) =>
  fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        pdrTest(id: ${testId}) {
          id
          ticket
          created
          updated
          questions {
            id
            question
            image
            answers {
              text
              image
              id
              isCorrect: is_correct
            }
          }
          answers {
            question answer
          }
          finished
          canBeContinued: can_be_continued
          isStrict: is_strict
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.pdrTest);

export const SET_CURRENT_TEST = 'SET_CURRENT_TEST';

export const setCurrentTest = payload => ({ type: SET_CURRENT_TEST, payload });

export const SET_IS_STRICT = 'SET_IS_STRICT';

export const setIsStrict = payload => ({ type: SET_IS_STRICT, payload });

export const pdrSubscriptionRequest = async fetch =>
  fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        pdrSubscription {
          id
          isActive: is_active
          validTill: valid_till
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.pdrSubscription);

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

export const setSubscription = payload => ({ type: SET_SUBSCRIPTION, payload });

export const SET_CURRENT_PART = 'SET_CURRENT_PART';
export const setCurrentPart = query => ({
  type: SET_CURRENT_PART,
  payload: query,
});
