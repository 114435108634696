import { GET_CAR_ITEM, SET_CAR_ITEM } from '../actions/carsActions';

export default function(state = {}, action) {
  switch (action.type) {
    case SET_CAR_ITEM:
      return action.payload;
    case GET_CAR_ITEM:
      return {};
    default:
      return state;
  }
}
