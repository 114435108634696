import pick from 'lodash/pick';
import compact from 'lodash/compact';
import toPairs from 'lodash/toPairs';
import isBoolean from 'lodash/isBoolean';
import join from 'lodash/join';

export const cleanQuery = (query, allowedKeys) => pick(query, allowedKeys);

export const buildGraphqlQuery = query => {
  const queryList = compact(
    toPairs(query).map(([k, v]) => {
      if (!isBoolean(v) && !v) {
        return null;
      }
      return `${k}: ${v}`;
    }),
  );
  if (queryList.length > 0) {
    return `(${join(queryList, ', ')})`;
  }
  return '';
};
