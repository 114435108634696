import { buildGraphqlQuery, cleanQuery } from '../../data/utils';

export const FETCH_MODIFICATIONS = 'featch_modifications';
export const SET_MODIFICATIONS = 'SET_MODIFICATIONS';

export const setModifications = payload => ({
  type: SET_MODIFICATIONS,
  payload,
  meta: {
    loading: false,
  },
});

export async function fetchModifications(fetch, query) {
  const allowedKeys = [
    'make',
    'model',
    'year',
    'fuel',
    'body',
    'drive',
    'gears_type',
    'search',
  ];
  const graphqlQuery = buildGraphqlQuery(cleanQuery(query, allowedKeys));

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        modifications${graphqlQuery} {
          id
          title
          make {
            title
          }
          model {
            title
          }
          techdata {
            fuel {
              id
              title
            }
            body {
              id
              title
            }
            drive {
              id
              title
            }
            gears_type {
              id
              title
            }
            power
            engine_capacity
            year_start
            year_stop
          }
          generation {
            images {
              url
            }
          }
          years
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.modifications);
}

export function getModifications(fetch, search) {
  return dispatch => {
    fetchModifications(fetch, search).then(modifications => {
      dispatch(setModifications(modifications));
    });
  };
}
