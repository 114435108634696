import { loading } from './runtime';

export const SET_ORDER_SERVICES = 'SET_ORDER_SERVICES';
export const SET_ORDER_OFFER = 'SET_ORDER_OFFER';

export function setOrderServices(payload) {
  return {
    type: SET_ORDER_SERVICES,
    payload,
  };
}

export function setOrderOffer(payload) {
  return {
    type: SET_ORDER_OFFER,
    payload,
  };
}

export async function fetchServicesRequest(fetch) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        services{
          id
          price
          period {
            title
            days
          }
          meta {
            title
            slug
            properties {
              title
              slug
            }
            description
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.services);
}

export function fetchServices(fetch) {
  return dispatch => {
    fetchServicesRequest(fetch).then(services => {
      dispatch(setOrderServices(services));
    });
  };
}

export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export function failCreateOrder(payload) {
  return {
    type: CREATE_ORDER_FAIL,
    payload,
    meta: {
      loading: false,
    },
  };
}

export function successCreateOrder(payload) {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload,
    meta: {
      loading: false,
    },
  };
}

export function createOrderRequest(fetch, order) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `mutation Mutation($order:OrderInputType) {
        createOrder(order: $order) {
          invoice {
            number
            amount
          }
          liqpay {
            signature
            data
          }
        }
      }`,
      variables: { order },
    }),
  }).then(response => response.json());
}

export function createOrder(fetch, order) {
  return dispatch => {
    dispatch(loading());

    createOrderRequest(fetch, order).then(data => {
      if (data.errors) {
        dispatch(failCreateOrder(['Помилка сервера']));
      } else {
        dispatch(successCreateOrder(data.data.createOrder));
      }
    });
  };
}
