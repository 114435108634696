import URI from 'urijs';
import history from '../history';
import { RESOLVE_SEARCH_QUERY } from '../actions/search/searchBarActions';

const defaultState = {
  resolved: {},
  query: '',
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case RESOLVE_SEARCH_QUERY:
      history.push(new URI('/cars').search(action.payload).toString());
      return { ...state, resolved: action.payload };
    default:
      return state;
  }
}
