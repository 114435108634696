import { SET_USER_PHONES, RESET_USER } from '../actions/authActions';

const defaultState = {
  phones: [],
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case SET_USER_PHONES:
      return { ...state, phones: action.payload };
    case RESET_USER:
      return { ...defaultState };
    default:
      return state;
  }
}
