export const DAY_BEST_SERVICE_SLUG = 'day_best';

export const BEST_PRICE_SERVICE_SLUG = 'best_price';

export const ALL_INCLUSIVE_SERVICE_SLUG = 'maximum';

export const ALL_INCLUSIVE_PRO_SERVICE_SLUG = 'maximum_pro';

export const REFRESH_SERVICE_SLUG = 'refresh';

export const DISCOUNT_LABEL_SERVICE_SLUG = 'mark_with_discount';

export const DEFAULT_PROPOSAL_SLUG = ALL_INCLUSIVE_SERVICE_SLUG;

export const SERVICES_ON_HOME_SLUG = `${BEST_PRICE_SERVICE_SLUG},${ALL_INCLUSIVE_SERVICE_SLUG},${ALL_INCLUSIVE_PRO_SERVICE_SLUG}`;
