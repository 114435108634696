import {
  SET_SEARCHFORM_MAKES,
  SET_SEARCHFORM_MODELS,
  SET_SEARCHFORM_LOCATIONS,
  SET_SEARCHFORM_BODY,
  SET_SEARCHFORM_FUEL,
  SET_SEARCHFORM_GEARS_TYPE,
  SET_SEARCHFORM_QUERY,
} from '../actions/search/formActions';

const defaultState = {
  makes: [],
  models: [],
  locations: [],
  body: [],
  fuel: [],
  gearsType: [],
  searchQuery: {},
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case SET_SEARCHFORM_MAKES:
      return { ...state, makes: action.payload };
    case SET_SEARCHFORM_MODELS:
      return { ...state, models: action.payload };
    case SET_SEARCHFORM_LOCATIONS:
      return { ...state, locations: action.payload };
    case SET_SEARCHFORM_FUEL:
      return { ...state, fuel: action.payload };
    case SET_SEARCHFORM_BODY:
      return { ...state, body: action.payload };
    case SET_SEARCHFORM_GEARS_TYPE:
      return { ...state, gearsType: action.payload };
    case SET_SEARCHFORM_QUERY:
      return { ...state, searchQuery: action.payload };
    default:
      return state;
  }
}
