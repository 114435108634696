import React from 'react';
import { intlShape } from 'react-intl';

import reduce from 'lodash/reduce';
import join from 'lodash/join';
import startsWith from 'lodash/startsWith';
import slice from 'lodash/slice';
import replace from 'lodash/replace';

export const DEFAULT_LOCALE = 'uk-UA';
export const LOCALES = ['uk-UA', 'ru-UA'];
export const ENABLED_LOCALES = ['uk-UA', 'ru-UA'];

export const localeDict = {
  'uk-UA': 'Українською',
  'ru-UA': 'Мовою окупантів',
};

export const getPrefix = (locale = DEFAULT_LOCALE) => locale.split('-')[0];

export const cleanUrl = (url, locale) =>
  locale !== DEFAULT_LOCALE
    ? join(slice(url, `/${getPrefix(locale)}`.length), '')
    : url;

export const makeLocaleUrl = (url, locale) =>
  locale !== DEFAULT_LOCALE ? `/${getPrefix(locale)}${url}` : url;

export const cleanLocaleFromUrl = (url, locale) => {
  const checkPrefix = `/${getPrefix(locale)}/`;
  return startsWith(url, checkPrefix) ? replace(url, checkPrefix, '/') : url;
};

export const getLocaleByPrefix = prefix =>
  LOCALES.find(item => item.indexOf(`${prefix}-`) === 0);

export const getLocaleFromPath = pathname => {
  let currentLocale = DEFAULT_LOCALE;
  LOCALES.forEach(locale => {
    if (pathname.indexOf(`/${getPrefix(locale)}/`) === 0) {
      currentLocale = locale;
      return false;
    }
    return true;
  });
  return currentLocale;
};

export const extendRoutes = routes =>
  reduce(
    LOCALES,
    (result, locale) => {
      const { path, children, action } = result;
      return {
        path,
        children: [
          {
            path: `/${getPrefix(locale)}`,
            children: [...children],
            action,
          },
          ...children,
        ],
        action,
      };
    },
    { ...routes },
  );

export const makeLocalize = (intl, messages) => (message, context = {}) =>
  intl.formatMessage(messages[message], context);

export const getDisplayName = ({ displayName, name }) =>
  displayName || name || 'Component';

export const withLocalize = messages => WrappedComponent => {
  class WithLocalize extends React.Component {
    static contextTypes = {
      intl: intlShape.isRequired,
    };

    localize = (message, context = {}) => {
      const { intl } = this.context;
      return intl.formatMessage(messages[message], context);
    };

    render() {
      return <WrappedComponent {...this.props} localize={this.localize} />;
    }
  }

  WithLocalize.displayName = `WithLocalize(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithLocalize;
};
