/* eslint-disable global-require */
import { extendRoutes } from '../locales';
// The top-level (parent) route
const baseRoutes = {
  path: '/',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/add',
      load: () => import(/* webpackChunkName: 'add' */ './add'),
    },
    {
      path: '/add/success/:id',
      load: () => import(/* webpackChunkName: 'add' */ './add/success'),
    },
    {
      path: '/edit/:id',
      load: () => import(/* webpackChunkName: 'add' */ './add'),
    },
    {
      path: '/cars',
      load: () => import(/* webpackChunkName: 'cars' */ './cars'),
    },
    {
      path: '/car/:id',
      load: () => import(/* webpackChunkName: 'caritem' */ './cars/item'),
    },
    {
      path: '/search',
      load: () => import(/* webpackChunkName: 'site' */ './search'),
    },
    {
      path: '/order/:id',
      load: () => import(/* webpackChunkName: 'core' */ './order'),
    },
    {
      path: '/payment/result/',
      load: () => import(/* webpackChunkName: 'core' */ './payment/result'),
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'core' */ './login'),
    },
    {
      path: '/logout',
      load: () => import('./logout'),
    },
    // {
    //   path: '/dashboard',
    //   load: () => import('./personal'),
    // },
    {
      path: '/dashboard/cars',
      load: () => import(/* webpackChunkName: 'dashboard' */ './personal/cars'),
    },
    {
      path: '/register',
      load: () => import(/* webpackChunkName: 'register' */ './register'),
    },
    {
      path: '/register/success',
      load: () =>
        import(/* webpackChunkName: 'register' */ './register/success'),
    },
    {
      path: '/activate/:key',
      load: () => import(/* webpackChunkName: 'core' */ './activate'),
    },
    {
      path: '/blog',
      load: () => import(/* webpackChunkName: 'blog' */ './blog'),
    },
    {
      path: '/blog/:slug',
      load: () => import(/* webpackChunkName: 'blog' */ './blog/item'),
    },
    {
      path: '/privacy',
      load: () => import(/* webpackChunkName: 'site' */ './privacy'),
    },
    {
      path: '/prodati/avto',
      load: () => import(/* webpackChunkName: 'site' */ './sell_avto'),
    },
    // TO DEVELOP
    {
      path: '/avtosalons',
      load: () => import(/* webpackChunkName: 'develop' */ './develop'),
    },
    {
      path: '/profile',
      load: () => import(/* webpackChunkName: 'develop' */ './develop'),
    },
    {
      path: '/dashboard',
      load: () => import(/* webpackChunkName: 'develop' */ './develop'),
    },
    {
      path: '/statistics',
      load: () => import(/* webpackChunkName: 'develop' */ './develop'),
    },
    {
      path: '/payment',
      load: () => import(/* webpackChunkName: 'dashboard' */ './payment'),
    },
    {
      path: '/advertisement',
      load: () => import(/* webpackChunkName: 'develop' */ './develop'),
    },
    {
      path: '/adverts',
      load: () => import(/* webpackChunkName: 'develop' */ './develop'),
    },
    // {
    //   path: '/admin',
    //   load: () => import(/* webpackChunkName: 'admin' */ './admin'),
    // },
    {
      path: '/pages/:page',
      load: () => import(/* webpackChunkName: 'site' */ './infopages'),
    },
    {
      path: '/pdr/subscribe/',
      load: () => import(/* webpackChunkName: 'pdr' */ './pdr/subscribe'),
    },
    {
      path: '/pdr/buy/',
      load: () => import(/* webpackChunkName: 'pdr' */ './pdr/buy'),
    },
    {
      path: '/pdr/bilet/:id',
      load: () => import(/* webpackChunkName: 'pdr' */ './pdr/ticket'),
    },
    {
      path: '/pdr/start/:mode',
      load: () => import(/* webpackChunkName: 'pdr' */ './pdr/start_exam'),
    },
    {
      path: '/pdr/:id',
      load: () => import(/* webpackChunkName: 'pdr' */ './pdr/exam'),
    },
    {
      path: '/pdr/',
      load: () => import(/* webpackChunkName: 'pdr' */ './pdr'),
    },
    {
      path: '/fuel/',
      load: () => import(/* webpackChunkName: 'fuel' */ './fuel'),
    },
    {
      path: '/fuel/(.*)',
      load: () => import(/* webpackChunkName: 'fuel' */ './fuel/item'),
    },
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'seo' */ './seo'),
    },
    // Wildcard routes, e.g. { path: '*', ... } (must go last)
    // {
    //   path: '*',
    //   load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    // },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Автопрод.com'}`;
    route.description = route.description || '';

    return route;
  },
};

const routes = extendRoutes(baseRoutes);

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
