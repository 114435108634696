import {
  SET_ORDER_OFFER,
  SET_ORDER_SERVICES,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
} from '../actions/orderActions';

const defaultState = {
  services: [],
  offer: null,
  order: null,
  errors: null,
};

export default function order(state = defaultState, action) {
  switch (action.type) {
    case SET_ORDER_SERVICES:
      return { ...state, services: action.payload };
    case SET_ORDER_OFFER:
      return { ...state, offer: action.payload };
    case CREATE_ORDER_SUCCESS:
      return { ...state, order: action.payload };
    case CREATE_ORDER_FAIL:
      return { ...state, order: null, errors: action.payload };
    default:
      return state;
  }
}
