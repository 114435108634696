import { SET_MAKES } from '../../actions/catalog/makeActions';

export default function(state = [], action) {
  switch (action.type) {
    case SET_MAKES:
      return action.payload;
    default:
      return state;
  }
}
