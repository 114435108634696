import { SET_PAYED_CARS } from 'actions/app/home';
import { SET_BLOGS_TO_HOME_PAGE } from 'actions/blogsActions';

const defaultState = {
  payedCars: {
    results: [],
  },
  blogs: {
    items: [],
  },
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case SET_PAYED_CARS:
      return { ...state, payedCars: action.payload };
    case SET_BLOGS_TO_HOME_PAGE:
      return { ...state, blogs: action.payload };
    default:
      return state;
  }
}
