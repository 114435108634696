export const FETCH_MODELS = 'featch_models';
export const SET_MODELS = 'SET_MODELS';

export const setModels = payload => ({ type: SET_MODELS, payload });

export async function fetchModels(fetch, makeId) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{models(parent: ${makeId}){id,title,slug}}`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.models);
}

export function getModels(fetch, makeId, success) {
  return dispatch => {
    fetchModels(fetch, makeId).then(models => {
      if (success) {
        dispatch(success(models));
      } else {
        dispatch(setModels(models));
      }
    });
  };
}
