export const SET_SCHEMA_BREADCRUMBS = 'SET_SCHEMA_BREADCRUMBS';

export const setSchemaBreadcrumbs = payload => ({
  type: SET_SCHEMA_BREADCRUMBS,
  payload,
});

export const SET_SCHEMA_VEHICLE_OFFER = 'SET_SCHEMA_VEHICLE_OFFER';

export const setSchemaVehicleOffer = payload => ({
  type: SET_SCHEMA_VEHICLE_OFFER,
  payload,
});

export const SET_SCHEMA_PRODUCT = 'SET_SCHEMA_PRODUCT';

export const setSchemaProduct = payload => ({
  type: SET_SCHEMA_PRODUCT,
  payload,
});

export const SET_SCHEMA_CLEAN = 'SET_SCHEMA_CLEAN';

export const setSchemaClean = () => ({
  type: SET_SCHEMA_CLEAN,
});
