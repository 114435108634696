import { SET_MODIFICATIONS } from '../../actions/catalog/modificationActions';

export default function(state = [], action) {
  switch (action.type) {
    case SET_MODIFICATIONS:
      return action.payload;
    default:
      return state;
  }
}
