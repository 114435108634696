import { loading } from './runtime';
import { getProfile, resetProfile, setProfile } from './profileActions';
import { decodeToken } from '../auth';

export const SET_USER_PHONES = 'SET_USER_PHONES';
export const RESET_USER = 'RESET_USER';

export function resetUser() {
  return {
    type: RESET_USER,
  };
}

export function setUserPhones(payload) {
  return {
    type: SET_USER_PHONES,
    payload,
  };
}

export function fetchPhones(fetch, userId) {
  let graphqlQuery = '';
  if (userId) {
    graphqlQuery = `(user: ${userId})`;
  }
  return dispatch => {
    fetch('/graphql', {
      body: JSON.stringify({
        query: `{phones${graphqlQuery}{id,number,description}}`,
      }),
    })
      .then(response => response.json())
      .then(data => dispatch(setUserPhones(data.data.phones)));
  };
}

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export function authError(errors) {
  return {
    type: AUTH_ERROR,
    payload: { errors },
  };
}

export function authPhone(fetch, { number, code }, next) {
  return dispatch => {
    fetch('/graphql', {
      body: JSON.stringify({
        query: `{jwtPhone(number: "${number}"${
          code ? `, code: "${code}"` : ''
        }) {
          token
          non_field_errors
          code
        }}`,
      }),
    })
      .then(response => response.json())
      .then(data => {
        const result = data.data.jwtPhone;
        if (result.token) {
          dispatch(getProfile(fetch));
          return dispatch({
            type: AUTH_LOGIN,
            payload: {
              session: true,
              token: result.token,
              ...decodeToken(result.token),
              next,
            },
            meta: {
              loading: false,
            },
          });
        }
        return dispatch(authError(result));
      });
  };
}

export function authLogin(fetch, { username, password }, next) {
  return dispatch => {
    fetch('/graphql', {
      body: JSON.stringify({
        query: `{jwt(username: "${username}", password: "${password}") {
          token
          non_field_errors
          password
          username
        }}`,
      }),
    })
      .then(response => response.json())
      .then(data => {
        const result = data.data.jwt;
        if (result.token) {
          dispatch(getProfile(fetch));
          return dispatch({
            type: AUTH_LOGIN,
            payload: {
              session: true,
              token: result.token,
              ...decodeToken(result.token),
              next,
            },
            meta: {
              loading: false,
            },
          });
        }
        return dispatch(authError(result));
      });
  };
}

export async function authVerifyRequest(fetch, token) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        jwtVerify(token:"${token}") {
          token
          non_field_errors
          password
          username
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(({ data }) => {
      if (data && data.jwtVerify) {
        return data.jwtVerify.token;
      }
      return undefined;
    });
}

export function authLogout(fetch) {
  return dispatch => {
    fetch('/graphql', {
      body: JSON.stringify({
        query: '{jwtLogout{status}}',
      }),
    }).then(() => {
      dispatch(resetUser());
      dispatch(resetProfile());
      return dispatch({
        type: AUTH_LOGOUT,
        payload: {
          session: false,
        },
        meta: {
          loading: false,
        },
      });
    });
  };
}

export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';

export function authRegisterError(errors) {
  return {
    type: AUTH_REGISTER_ERROR,
    payload: { errors },
    meta: {
      loading: false,
    },
  };
}

export function authRegister(fetch, payload, next) {
  return dispatch => {
    dispatch(loading());
    fetch('/graphql', {
      body: JSON.stringify({
        query: `mutation Mutation($data:UserInputType) {
          registerUser(data: $data) {
            token
            non_field_errors
            password1
            username
            email
            user{username}
          }
        }`,
        variables: { data: payload },
      }),
    })
      .then(response => response.json())
      .then(data => {
        const result = data.data.registerUser;
        if (result.token) {
          dispatch(setProfile(result.user));
          dispatch({
            type: AUTH_REGISTER,
            payload: {
              session: true,
              token: result.token,
              ...decodeToken(result.token),
              next,
            },
            meta: {
              loading: false,
            },
          });
        } else {
          dispatch(authRegisterError(result));
        }
      });
  };
}

export async function authActivateRequest(fetch, key) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        accountActivate(key:"${key}") {
          detail
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.accountActivate.detail);
}
