import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loadingReducer } from 'redux-loading';
import { reducer as notifications } from 'react-notification-system-redux';

import user from './user';
import runtime from './runtime';
import cars from './cars_reducer';
import makes from './catalog/make_reducer';
import models from './catalog/model_reducer';
import modifications from './catalog/modification_reducer';
import offer from './offer_reducer';
import menu from './app/menu_reducer';
import breadcrumbs from './app/breadcrumbs_reducer';
import home from './app/home_reducer';
import device from './app/device_reducer';
import auth from './auth_reducer';
import cities from './cities_reducer';
import create from './create_reducer';
import imageContainer from './image_container_reducer';
import searchFormReducer from './search_form_reducer';
import addFormReducer from './add_form_reducer';
import searchBar from './search_bar_reducer';
import profile from './profile_reducer';
import personal from './personal/personal_reducer';
import blog from './blog_reducer';
import order from './order_reducer';
import schema from './schema_reducer';
import adsense from './ad_reducer';
import intl from './intl';
import pdr from './pdr_reducer';
import fuel from './fuel_reducer';

export default combineReducers({
  user,
  profile,
  runtime,
  cars,
  create,
  makes,
  models,
  modifications,
  cities,
  offer,
  menu,
  auth,
  imageContainer,
  breadcrumbs,
  searchBar,
  personal,
  home,
  blog,
  search_form: searchFormReducer,
  add_form: addFormReducer,
  form: formReducer,
  loading: loadingReducer,
  notifications,
  order,
  schema,
  adsense,
  intl,
  pdr,
  fuel,
  device,
});
