import history from '../history';
import {
  CREATE_CAR_ITEM,
  CREATE_CAR_FAIL,
  UPDATE_CAR_ITEM,
  UPDATE_CAR_FAIL,
} from '../actions/carsActions';
import {
  CREATE_SET_MODIFICATION,
  CREATE_SET_IMAGE_CONTAINER,
  CREATE_SET_CAR_TO_EDIT,
  CREATE_SET_SELECTED_MODIFICATION,
  CREATE_SORT_IMAGES,
  CREATE_DELETE_IMAGE,
} from '../actions/createActions';

const initialState = {
  modification: {},
  imageContainer: {},
  car: null,
  images: [],
  createdCar: null,
  updatedCar: null,
};

function appendImagesFromContainer(currentImages, images) {
  const imageIds = currentImages.map(i => i.image_id);
  const notFoundedImages = images.filter(
    item => !imageIds.includes(item.image_id),
  );
  return [...currentImages, ...notFoundedImages];
}

export default function create(state = initialState, action) {
  switch (action.type) {
    case CREATE_SET_MODIFICATION:
      return {
        ...state,
        ...{
          modification: Object.assign({}, state.modification, action.payload),
        },
      };
    case CREATE_SET_SELECTED_MODIFICATION:
      return {
        ...state,
        modification: {
          ...state.modification,
          selected: action.payload,
        },
      };
    case CREATE_SET_IMAGE_CONTAINER:
      return {
        ...state,
        ...action.payload,
        images: appendImagesFromContainer(
          state.images,
          action.payload.imageContainer.images || [],
        ),
      };
    case CREATE_CAR_ITEM:
      history.push(
        action.payload.next || `/add/success/${action.payload.created.id}`,
      );
      return { ...initialState, createdCar: action.payload.created };
    case UPDATE_CAR_ITEM:
      history.push(action.payload.next || '/dashboard/cars');
      return { ...initialState, updatedCar: action.payload.updated };
    case CREATE_CAR_FAIL:
      return { ...state, ...action.payload };
    case UPDATE_CAR_FAIL:
      return { ...state, ...action.payload };
    case CREATE_SET_CAR_TO_EDIT:
      return {
        ...state,
        car: action.payload,
        images:
          action.payload && action.payload.images ? action.payload.images : [],
      };
    case CREATE_SORT_IMAGES:
      return { ...state, images: action.payload };
    case CREATE_DELETE_IMAGE:
      return {
        ...state,
        images: state.images.filter(item => item.id !== action.payload),
      };
    default:
      return state;
  }
}
