import {
  SET_SCHEMA_BREADCRUMBS,
  SET_SCHEMA_VEHICLE_OFFER,
  SET_SCHEMA_PRODUCT,
  SET_SCHEMA_CLEAN,
} from 'actions/schemaActions';

const defaultState = {
  breadcrumbs: [],
  vehicleOffer: {},
  product: undefined,
};

export default function runtime(state = defaultState, action) {
  switch (action.type) {
    case SET_SCHEMA_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    case SET_SCHEMA_VEHICLE_OFFER:
      return {
        ...state,
        vehicleOffer: action.payload,
      };
    case SET_SCHEMA_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case SET_SCHEMA_CLEAN:
      return { ...defaultState };
    default:
      return state;
  }
}
