import { SET_CAR_OFFERS } from '../actions/carsActions';

const defaultState = {
  response: {},
  query: {},
  inlist: {},
  top: {},
};

export default function offers(state = defaultState, action) {
  switch (action.type) {
    case SET_CAR_OFFERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
