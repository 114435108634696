import { SET_BREADCRUMBS } from '../../actions/app/breadcrumbs';

const DEFAULT_BREADCRUMBS = [
  {
    href: '/',
    title: 'Головна',
    active: true,
  },
];

export default function(state = DEFAULT_BREADCRUMBS, action) {
  switch (action.type) {
    case SET_BREADCRUMBS:
      return action.payload;
    default:
      return state;
  }
}
