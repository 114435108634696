import { getMakes as baseFetchMakes } from '../catalog/makeActions';
import { getModels as baseFetchModels } from '../catalog/modelActions';
import { getCatalog as baseFetchCatalog } from '../catalog/actions';
import { getLocationsTree as baseFetchCities } from '../locationActions';

export const SET_SEARCHFORM_MAKES = 'SET_SEARCHFORM_MAKES';
export const SET_SEARCHFORM_MODELS = 'SET_SEARCHFORM_MODELS';
export const SET_SEARCHFORM_LOCATIONS = 'SET_SEARCHFORM_LOCATIONS';
export const SET_SEARCHFORM_FUEL = 'SET_SEARCHFORM_FUEL';
export const SET_SEARCHFORM_BODY = 'SET_SEARCHFORM_BODY';
export const SET_SEARCHFORM_GEARS_TYPE = 'SET_SEARCHFORM_GEARS_TYPE';
export const SET_SEARCHFORM_QUERY = 'SET_SEARCHFORM_QUERY';

export const setSearchQuery = payload => ({
  type: SET_SEARCHFORM_QUERY,
  payload,
});

export const setFuel = payload => ({ type: SET_SEARCHFORM_FUEL, payload });
export const fetchFuel = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'fuel', setFuel));

export const setBody = payload => ({ type: SET_SEARCHFORM_BODY, payload });
export const fetchBody = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'body', setBody));

export const setGearsType = payload => ({
  type: SET_SEARCHFORM_GEARS_TYPE,
  payload,
});
export const fetchGearsType = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'gears_type', setGearsType));

export const setMakes = payload => ({ type: SET_SEARCHFORM_MAKES, payload });
export const fetchMakes = fetch => dispatch =>
  dispatch(baseFetchMakes(fetch, setMakes));

export const setModels = payload => ({ type: SET_SEARCHFORM_MODELS, payload });
export const fetchModels = (fetch, makeId) => dispatch =>
  dispatch(baseFetchModels(fetch, makeId, setModels));

export const setLocations = payload => ({
  type: SET_SEARCHFORM_LOCATIONS,
  payload,
});
export const fetchLocations = fetch => dispatch =>
  dispatch(baseFetchCities(fetch, setLocations));
