import { ADSENSE_RELOAD } from 'actions/adActions';
import { uuid } from 'uuidv4';

const defaultState = {};

export default function runtime(state = defaultState, action) {
  switch (action.type) {
    case ADSENSE_RELOAD:
      return {
        ...state,
        id: uuid(),
      };
    default:
      return state;
  }
}
