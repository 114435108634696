export const FETCH_CATALOG = 'FETCH_CATALOG';
export const SET_CATALOG = 'SET_CATALOG';

export const setCatalog = payload => ({ type: SET_CATALOG, payload });

export async function fetchCatalog(fetch, parent) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{catalog(parent: "${parent}"){id,title}}`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.catalog);
}

export function getCatalog(fetch, parent, success) {
  return dispatch => {
    fetchCatalog(fetch, parent).then(catalog => {
      if (success) {
        dispatch(success(catalog));
      } else {
        dispatch(setCatalog(catalog));
      }
    });
  };
}
