import { buildGraphqlQuery, cleanQuery } from '../data/utils';

export async function blogsItemsRequest(fetch, payload = {}) {
  const allowedKeys = ['limit', 'offset', 'order', 'fields'];
  const cleanedQuery = cleanQuery(payload, allowedKeys);
  const graphqlQuery = buildGraphqlQuery(cleanedQuery);

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        blogs${graphqlQuery} {
          meta {
            total_count
          }
          items {
            id
            title
            intro
            thumbnail { url }
            big { url }
            meta {
              first_published_at
              slug
            }
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.blogs);
}

export async function blogItemRequest(fetch, slug) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        blog(id: "${slug}") {
          id
          title
          intro
          html
          thumbnail {
            url
          }
          big {
            url
          }
          meta {
            first_published_at
            slug
            seo_title
            search_description
          }
        }
      }
      `,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.blog);
}

export function homeBlogsItemsRequest(fetch) {
  return blogsItemsRequest(fetch, {
    // order: '"random"',
    limit: 3,
  });
}

export const SET_BLOGS_TO_HOME_PAGE = 'SET_BLOGS_TO_HOME_PAGE';

export function setBlogsToHomePage(payload) {
  return {
    type: SET_BLOGS_TO_HOME_PAGE,
    payload,
  };
}

export const FETCH_BLOGS_TO_HOME_PAGE = 'FETCH_BLOGS_TO_HOME_PAGE';

export function fetchBlogsToHomePage(fetch) {
  return dispatch => {
    homeBlogsItemsRequest(fetch).then(data => {
      dispatch(setBlogsToHomePage(data));
    });
  };
}

export const SET_BLOGS_TO_WIDGET = 'SET_BLOGS_TO_WIDGET';

export function setBlogsToWidget(payload) {
  return {
    type: SET_BLOGS_TO_WIDGET,
    payload,
  };
}

export function widgetBlogsItemsRequest(fetch) {
  return blogsItemsRequest(fetch, {
    order: '"random"',
    limit: 3,
  });
}

export function fetchBlogsToWidget(fetch) {
  return dispatch => {
    widgetBlogsItemsRequest(fetch).then(data => {
      dispatch(setBlogsToWidget(data));
    });
  };
}

export const SET_BLOG_LIST = 'SET_BLOG_LIST';

export function setBlogList(data, query = {}) {
  return {
    type: SET_BLOG_LIST,
    payload: { data, query },
  };
}

export function fetchBlogList(fetch, payload = {}) {
  return dispatch => {
    blogsItemsRequest(fetch, payload).then(data => {
      dispatch(setBlogList(data, payload));
    });
  };
}

export const SET_BLOG_ITEM = 'SET_BLOG_ITEM';

export function setBlogItem(payload) {
  return {
    type: SET_BLOG_ITEM,
    payload,
  };
}
