import { SET_MODELS } from '../../actions/catalog/modelActions';

export default function(state = [], action) {
  switch (action.type) {
    case SET_MODELS:
      return action.payload;
    default:
      return state;
  }
}
