export const CREATE_SET_MODIFICATION = 'CREATE_SET_MODIFICATION';
export const CREATE_SET_IMAGE_CONTAINER = 'CREATE_SET_IMAGE_CONTAINER';
export const CREATE_SET_CAR_TO_EDIT = 'CREATE_SET_CAR_TO_EDIT';
export const CREATE_SET_SELECTED_MODIFICATION =
  'CREATE_SET_SELECTED_MODIFICATION';

export function setModification({ search, modifications, selected }) {
  return {
    type: CREATE_SET_MODIFICATION,
    payload: {
      search,
      modifications,
      selected,
    },
  };
}

export function setSelectedModification(modification) {
  return {
    type: CREATE_SET_SELECTED_MODIFICATION,
    payload: modification,
  };
}

export function setImageContainer(imageContainer) {
  return {
    type: CREATE_SET_IMAGE_CONTAINER,
    payload: {
      imageContainer,
    },
    meta: {
      loading: false,
    },
  };
}

export function setCarToEdit(payload) {
  return {
    type: CREATE_SET_CAR_TO_EDIT,
    payload,
  };
}

export async function carForEditItemRequest(fetch, id) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        car(id: ${id}) {
          id
          title
          make {
            id
          }
          model {
            id
          }
          year
          mileage
          fuel {
            id
          }
          engine_capacity
          gears_type {
            id
          }
          drive {
            id
          }
          body {
            id
          }
          color {
            id
          }
          kredit {
            id
          }
          customs {
            id
          }
          other {
            id
          }
          description
          price
          price_currency
          phones {
            number
            description
          }
          location {
            id
          }
          images {
            id
            url
            sort
            image_id
          }
          modification: catalog_prototype {
            id
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.car);
}

export const CREATE_SORT_IMAGES = 'CREATE_SORT_IMAGES';

export const sortImages = images => ({
  type: CREATE_SORT_IMAGES,
  payload: images,
});

export const CREATE_DELETE_IMAGE = 'CREATE_DELETE_IMAGE';

export const deleteImage = imageId => ({
  type: CREATE_DELETE_IMAGE,
  payload: imageId,
});
