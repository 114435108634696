import ReactGA from 'react-ga4';

export const initialize = ({ trackingId, adsTrackingId }) => {
  ReactGA.initialize([
    // main tag
    {
      trackingId,
    },
    // ads
    {
      trackingId: adsTrackingId,
    },
  ]);
};
export const pageview = path =>
  ReactGA.send({
    hitType: 'pageview',
    page: path,
  });

export const event = args => ReactGA.event(args);

export const EVENT_CATEGORY_OFFER = 'offer';

export const EVENT_ACTION_SHOW_OFFER = 'offer_shown';

export const offerShownEvent = offerId =>
  event({
    category: EVENT_CATEGORY_OFFER,
    action: EVENT_ACTION_SHOW_OFFER,
    label: offerId,
  });

export const EVENT_ACTION_SHOW_OFFER_PHONES = 'offer_phones_shown';

export const offerPhonesShown = offerId =>
  event({
    category: EVENT_CATEGORY_OFFER,
    action: EVENT_ACTION_SHOW_OFFER_PHONES,
    label: offerId,
  });

export const EVENT_ACTION_OFFER_CREATED = 'offer_created_free';
export const offerCreatedFreeEvent = offerId =>
  event({
    category: EVENT_CATEGORY_OFFER,
    action: EVENT_ACTION_OFFER_CREATED,
    label: offerId,
  });

export const EVENT_ACTION_OFFER_PAYED = 'offer_payed';
export const offerPayedEvent = (billNumber, amount) =>
  event({
    category: EVENT_CATEGORY_OFFER,
    action: EVENT_ACTION_OFFER_PAYED,
    label: billNumber,
    value: amount,
  });

export const EVENT_ACTION_OFFER_FAILED_PAYMENT = 'offer_fail_payment';
export const offerFailPayment = (billNumber, amount) => {
  event({
    category: EVENT_CATEGORY_OFFER,
    action: EVENT_ACTION_OFFER_FAILED_PAYMENT,
    label: billNumber,
    value: amount,
  });
};
