/* eslint-disable import/prefer-default-export */

import { SET_RUNTIME_VARIABLE } from '../constants';

export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';

export function loading() {
  return {
    type: START_LOADING,
    meta: {
      loading: true,
    },
  };
}

export function finishLoading() {
  return {
    type: FINISH_LOADING,
    meta: {
      loading: false,
    },
  };
}

export function setRuntimeVariable({ name, value }) {
  return {
    type: SET_RUNTIME_VARIABLE,
    payload: {
      name,
      value,
    },
  };
}
