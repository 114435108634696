export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_TREE = 'FETCH_LOCATIONS_TREE';

export const SET_CITIES = 'SET_CITIES';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_LOCATIONS_TREE = 'SET_LOCATIONS_TREE';

export const setCities = payload => ({ type: SET_CITIES, payload });
export const setLocations = payload => ({ type: SET_LOCATIONS, payload });
export const setLocationsTree = payload => ({
  type: SET_LOCATIONS_TREE,
  payload,
});

export async function fetchCities(fetch) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: '{cities{id,slug,title}}',
    }),
  })
    .then(response => response.json())
    .then(data => data.data.cities);
}

export function getCities(fetch, success) {
  return dispatch => {
    fetchCities(fetch).then(cities => {
      if (success) {
        dispatch(success(cities));
      } else {
        dispatch(setCities(cities));
      }
    });
  };
}

export async function fetchLocations(fetch) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: '{locations{id,slug,title,parent,level}}',
    }),
  })
    .then(response => response.json())
    .then(data => data.data.locations);
}

export function getLocations(fetch, success) {
  return dispatch => {
    fetchLocations(fetch).then(locations => {
      if (success) {
        dispatch(success(locations));
      } else {
        dispatch(setLocations(locations));
      }
    });
  };
}

export async function fetchLocationsTree(fetch) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: '{locationsTree{id,title,slug,childrens{id,title,slug}}}',
    }),
  })
    .then(response => response.json())
    .then(data => data.data.locationsTree);
}

export function getLocationsTree(fetch, success) {
  return dispatch => {
    fetchLocationsTree(fetch).then(locationsTree => {
      if (success) {
        dispatch(success(locationsTree));
      } else {
        dispatch(setLocationsTree(locationsTree));
      }
    });
  };
}
