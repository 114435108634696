import {
  SET_OPERATORS,
  SET_LOCATIONS,
  SET_AVG_PRICES,
  SET_PRICES,
  SET_LOCATION_FILTER,
  SET_FUEL_TYPE_FILTER,
  SET_OPERATOR_FILTER,
} from '../actions/fuelActions';

const defaultState = {
  avgPrices: null,
  prices: null,
  operators: null,
  locations: null,
  locationFilter: null,
  fuelTypeFilter: null,
  operatorFilter: null,
};

export default function profile(state = defaultState, action) {
  switch (action.type) {
    case SET_OPERATORS:
      return { ...state, operators: action.payload };
    case SET_LOCATIONS:
      return { ...state, locations: action.payload };
    case SET_AVG_PRICES:
      return { ...state, avgPrices: action.payload };
    case SET_PRICES:
      return { ...state, prices: action.payload };
    case SET_LOCATION_FILTER:
      return { ...state, locationFilter: action.payload };
    case SET_FUEL_TYPE_FILTER:
      return { ...state, fuelTypeFilter: action.payload };
    case SET_OPERATOR_FILTER:
      return { ...state, operatorFilter: action.payload };
    default:
      return state;
  }
}
