import omit from 'lodash/omit';
import {
  success as notifySuccess,
  error as notifyFail,
} from 'react-notification-system-redux';
import {
  DAY_BEST_SERVICE_SLUG,
  ALL_INCLUSIVE_SERVICE_SLUG,
  ALL_INCLUSIVE_PRO_SERVICE_SLUG,
  BEST_PRICE_SERVICE_SLUG,
} from 'constants/services';
import { setSchemaVehicleOffer } from 'actions/schemaActions';
import { loading } from './runtime';
import { buildGraphqlQuery, cleanQuery } from '../data/utils';

// GET ITEMS LIST
export const GET_CAR_OFFERS = 'GET_CAR_OFFERS';
export const SET_CAR_OFFERS = 'SET_CAR_OFFERS';

export const setCarItems = payload => ({
  type: SET_CAR_OFFERS,
  payload,
  meta: {
    loading: false,
  },
});

export async function carItemsRequest(fetch, query) {
  const allowedKeys = [
    'page',
    'locations',
    'make',
    'model',
    'year_f',
    'year_t',
    'price_f',
    'price_t',
    'mileage_f',
    'mileage_t',
    'body',
    'fuel',
    'gears_type',
    'used',
    'my',
  ];
  const cleanedQuery = cleanQuery(query, allowedKeys);

  const graphqlQuery = buildGraphqlQuery(cleanedQuery);
  const dayBestQuery = buildGraphqlQuery({
    ...omit(cleanedQuery, 'page'),
    services: `"${DAY_BEST_SERVICE_SLUG},${ALL_INCLUSIVE_SERVICE_SLUG},${ALL_INCLUSIVE_PRO_SERVICE_SLUG}"`,
    page_size: 1,
    shuffle: true,
  });

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        cars${graphqlQuery} {
          prev,next,count,results {
            id,title
            model {full_title}
            fuel {title}
            engine_capacity
            gears_type {title}
            body {title}
            drive {title}
            customs {title}
            color {title}
            kredit {title}
            other {title}
            modification: catalog_prototype {
              title
              generation{
                images {url}
              }
            }
            description,year,price,price_currency,mileage,updated
            price_uah
            price_usd
            location {title}
            images {url}
            services {
              slug,
              properties{slug}
            }
            is_verified_by_payment
          }
        }
        inlist: cars(services: "${BEST_PRICE_SERVICE_SLUG},${ALL_INCLUSIVE_SERVICE_SLUG},${ALL_INCLUSIVE_PRO_SERVICE_SLUG}", page_size: 3, shuffle: true) {
          results {
            id,title
            model {full_title}
            fuel {title}
            engine_capacity
            gears_type {title}
            body {title}
            drive {title}
            customs {title}
            color {title}
            kredit {title}
            other {title}
            modification: catalog_prototype {
              title
              generation{
                images {url}
              }
            }
            description,year,price,price_currency,mileage,updated
            price_uah
            price_usd
            location {title}
            images {url}
            services {
              slug,
              properties{slug}
            }
            is_verified_by_payment
          }
        }
        top: cars${dayBestQuery} {
          results {
            id,title
            model {full_title}
            fuel {title}
            engine_capacity
            gears_type {title}
            body {title}
            drive {title}
            customs {title}
            color {title}
            kredit {title}
            other {title}
            modification: catalog_prototype {
              title
              generation{
                images {url}
              }
            }
            description,year,price,price_currency,mileage,updated
            price_uah
            price_usd
            location {title}
            images {url}
            services {
              slug,
              properties{slug}
            }
            is_verified_by_payment
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data);
}

export function fetchCars(fetch, query) {
  return dispatch => {
    carItemsRequest(fetch, query).then(({ cars, top, inlist }) => {
      dispatch(
        setCarItems({
          response: cars,
          top,
          inlist,
          query,
        }),
      );
    });
  };
}

export async function payedCarsItemsRequest(fetch, services, size, shuffle) {
  const graphqlQuery = buildGraphqlQuery({
    services: `"${services}"`,
    page_size: size || 1,
    shuffle: Boolean(shuffle),
  });

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        cars${graphqlQuery} {
          results {
            id,title
            model {full_title}
            fuel {title}
            engine_capacity
            gears_type {title}
            body {title}
            drive {title}
            customs {title}
            color {title}
            kredit {title}
            other {title}
            modification: catalog_prototype {
              title
              generation{
                images {url}
              }
            }
            description,year,price,price_currency,mileage,updated
            price_uah
            price_usd
            location {title}
            images {url}
            services {
              slug,
              properties{slug}
            }
            is_verified_by_payment
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data);
}

export function fetchPayedCars(fetch, services, size, shuffle, callbackAction) {
  return dispatch => {
    payedCarsItemsRequest(fetch, services, size, shuffle).then(({ cars }) => {
      dispatch(callbackAction(cars));
    });
  };
}

// GET ITEM
export const GET_CAR_ITEM = 'GET_CAR_ITEM';
export const SET_CAR_ITEM = 'SET_CAR_ITEM';

export const setCarItem = payload => dispatch => {
  dispatch(setSchemaVehicleOffer(payload));
  dispatch({ type: SET_CAR_ITEM, payload });
};

export async function carItemRequest(fetch, id) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        car(id: ${id}) {
          id
          title
          description
          fuel {
            title
          }
          engine_capacity
          drive {
            title
          }
          gears_type {
            title
          }
          body {
            title
          }
          make {
            slug
            title
          }
          model {
            slug
            title
            full_title
          }
          color {
            title
          }
          customs {
            title
          }
          kredit {
            title
          }
          other {
            title
          }
          modification: catalog_prototype {
            title
            model {
              full_title
            }
            generation {
              images {
                url
              }
            }
            techdata {
              engine_capacity
              power
              weight
              max_weight
              length
              width
              height
              wheelbase
              torque
              acceleration
              clearance
              year_start
              year_stop
              fuel_tank
              fuel_consumption_city
              fuel_consumption_highway
              fuel_consumption_combined
              trunk_volume_min
              trunk_volume_max
              engine_oil_amount
              fuel {
                title
              }
              drive {
                title
              }
              gears_type {
                title
              }
              body {
                title
              }
              properties {
                title
                full_title
              }
            }
          }
          year
          mileage
          price
          price_currency
          price_uah
          price_usd
          location {
            title
            slug
          }
          images {
            url
          }
          created
          updated
          phones {
            id
            number
            description
          }
          services {
            slug,
            properties{slug}
          }
          active
          is_verified_by_payment
        }
      }
      `,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.car);
}

export function getCatItem(fetch, id, success) {
  return dispatch => {
    carItemRequest(fetch, id).then(car => {
      if (success) {
        success(car);
      }
      dispatch(setCarItem(car));
    });
  };
}

// CREATE
export const CREATE_CAR_ITEM = 'CREATE_CAR_ITEM';
export const CREATE_CAR_FAIL = 'CREATE_CAR_FAIL';

export function createCarFail(errors) {
  return {
    type: CREATE_CAR_FAIL,
    payload: { errors },
    meta: {
      loading: false,
    },
  };
}

export function createCarItem(fetch, car, next) {
  return dispatch => {
    dispatch(loading());

    fetch('/graphql', {
      body: JSON.stringify({
        query: `mutation Mutation($car:CarItemInputType) {
          createCar(car: $car) {
            id
            title
            price,price_currency
          }
        }`,
        variables: { car },
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          dispatch(createCarFail(data.errors));
        } else {
          dispatch({
            type: CREATE_CAR_ITEM,
            payload: {
              created: data.data.createCar,
              next: next ? next(data.data.createCar.id) : null,
            },
            meta: {
              loading: false,
            },
          });
        }
      });
  };
}

// UPDATE CAR
export const UPDATE_CAR_ITEM = 'UPDATE_CAR_ITEM';
export const UPDATE_CAR_FAIL = 'UPDATE_CAR_FAIL';

export function updateCarFail(errors) {
  return {
    type: UPDATE_CAR_FAIL,
    payload: { errors },
    meta: {
      loading: false,
    },
  };
}

export function updateCarItem(fetch, car, title, next) {
  return dispatch => {
    dispatch(loading());

    fetch('/graphql', {
      body: JSON.stringify({
        query: `mutation Mutation($car:CarItemInputType) {
          updateCar(car: $car) {
            id
            title
            price,price_currency
          }
        }`,
        variables: { car },
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          dispatch(
            notifyFail({
              title: 'Помилка!',
              message: 'Виникла помилка!',
            }),
          );
          dispatch(updateCarFail(data.errors));
        } else {
          dispatch(
            notifySuccess({
              title: 'Оновлення успішне!',
              message: `Зміни у оголошення "${title}" внесені успішно.`,
            }),
          );

          dispatch({
            type: UPDATE_CAR_ITEM,
            payload: {
              updated: data.data.updatedCar,
              next: next ? next(data.data.updateCar.id) : null,
            },
            meta: {
              loading: false,
            },
          });
        }
      });
  };
}
