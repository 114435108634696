import { SET_PROFILE, RESET_PROFILE } from '../actions/profileActions';

const defaultState = {
  phones: [],
};

export default function profile(state = defaultState, action) {
  switch (action.type) {
    case SET_PROFILE:
      return { ...state, ...action.payload };
    case RESET_PROFILE:
      return { ...defaultState };
    default:
      return state;
  }
}
