import { getMakes as baseFetchMakes } from '../catalog/makeActions';
import { getModels as baseFetchModels } from '../catalog/modelActions';
import { getCatalog as baseFetchCatalog } from '../catalog/actions';
import { getLocationsTree as baseFetchCities } from '../locationActions';

export const SET_ADDFORM_MAKES = 'SET_ADDFORM_MAKES';
export const SET_ADDFORM_MODELS = 'SET_ADDFORM_MODELS';
export const SET_ADDFORM_LOCATIONS = 'SET_ADDFORM_LOCATIONS';
export const SET_ADDFORM_FUEL = 'SET_ADDFORM_FUEL';
export const SET_ADDFORM_BODY = 'SET_ADDFORM_BODY';
export const SET_ADDFORM_GEARS_TYPE = 'SET_ADDFORM_GEARS_TYPE';
export const SET_ADDFORM_DRIVE = 'SET_ADDFORM_DRIVE';
export const SET_ADDFORM_COLOR = 'SET_ADDFORM_COLOR';
export const SET_ADDFORM_CUSTOMS = 'SET_ADDFORM_CUSTOMS';
export const SET_ADDFORM_KREDIT = 'SET_ADDFORM_KREDIT';
export const SET_ADDFORM_OTHER = 'SET_ADDFORM_OTHER';

export const setOther = payload => ({ type: SET_ADDFORM_OTHER, payload });
export const fetchOther = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'other-properties', setOther));

export const setKredit = payload => ({ type: SET_ADDFORM_KREDIT, payload });
export const fetchKredit = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'kredit', setKredit));

export const setCustoms = payload => ({ type: SET_ADDFORM_CUSTOMS, payload });
export const fetchCustoms = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'customs', setCustoms));

export const setColor = payload => ({ type: SET_ADDFORM_COLOR, payload });
export const fetchColor = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'color', setColor));

export const setFuel = payload => ({ type: SET_ADDFORM_FUEL, payload });
export const fetchFuel = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'fuel', setFuel));

export const setBody = payload => ({ type: SET_ADDFORM_BODY, payload });
export const fetchBody = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'body', setBody));

export const setGearsType = payload => ({
  type: SET_ADDFORM_GEARS_TYPE,
  payload,
});
export const fetchGearsType = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'gears_type', setGearsType));

export const setDrive = payload => ({ type: SET_ADDFORM_DRIVE, payload });
export const fetchDrive = fetch => dispatch =>
  dispatch(baseFetchCatalog(fetch, 'drive', setDrive));

export const setMakes = payload => ({ type: SET_ADDFORM_MAKES, payload });
export const fetchMakes = fetch => dispatch =>
  dispatch(baseFetchMakes(fetch, setMakes));

export const setModels = payload => ({ type: SET_ADDFORM_MODELS, payload });
export const fetchModels = (fetch, makeId) => dispatch =>
  dispatch(baseFetchModels(fetch, makeId, setModels));

export const setLocations = payload => ({
  type: SET_ADDFORM_LOCATIONS,
  payload,
});
export const fetchLocations = fetch => dispatch =>
  dispatch(baseFetchCities(fetch, setLocations));
