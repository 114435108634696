export const FETCH_MAKES = 'fetch_makes';
export const SET_MAKES = 'SET_MAKES';

export const setMakes = payload => ({ type: SET_MAKES, payload });

export async function fetchMakes(fetch) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: '{makes{id,title,slug}}',
    }),
  })
    .then(response => response.json())
    .then(data => data.data.makes);
}

export function getMakes(fetch, success) {
  return dispatch => {
    fetchMakes(fetch).then(makes => {
      if (success) {
        dispatch(success(makes));
      } else {
        dispatch(setMakes(makes));
      }
    });
  };
}
