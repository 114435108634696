import { buildGraphqlQuery, cleanQuery } from '../data/utils';

export const avgFuelPricesRequest = async (fetch, payload = {}) => {
  const allowedKeys = ['fuelType', 'date', 'limit'];
  const cleanedQuery = cleanQuery(payload, allowedKeys);
  const graphqlQuery = buildGraphqlQuery(cleanedQuery);

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        fuelAvg${graphqlQuery} {
          results {
            fuel_type {
              id slug title
            }
            price
            currency
            date
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.fuelAvg);
};

export const SET_AVG_PRICES = 'SET_AVG_PRICES';

export const setAvgPrices = payload => ({ type: SET_AVG_PRICES, payload });

export const fuelPricesRequest = async (fetch, payload = {}) => {
  const allowedKeys = ['fuelType', 'date', 'location', 'operator', 'limit'];
  const cleanedQuery = cleanQuery(payload, allowedKeys);
  const graphqlQuery = buildGraphqlQuery(cleanedQuery);

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        fuelPrice${graphqlQuery} {
          results {
            fuel_type {
              id slug title
            }
            operator {
              title
              slug
            }
            location {
              title
              slug
            }
            price
            currency
            date
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.fuelPrice);
};

export const SET_PRICES = 'SET_PRICES';

export const setPrices = payload => ({ type: SET_PRICES, payload });

export const fuelOperatorsRequest = async (fetch, payload = {}) => {
  const allowedKeys = ['location'];
  const cleanedQuery = cleanQuery(payload, allowedKeys);
  const graphqlQuery = buildGraphqlQuery(cleanedQuery);

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{ fuelOperators${graphqlQuery} { id slug title } }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.fuelOperators);
};

export const SET_OPERATORS = 'SET_OPERATORS';

export const setFuelOperators = payload => ({ type: SET_OPERATORS, payload });

// export const SET_FILTERED_OPERATORS = 'SET_FILTERED_OPERATORS';
//
// export const setFilteredFuelOperators = payload => ({
//   type: SET_FILTERED_OPERATORS,
//   payload,
// });

export const fuelLocationsRequest = async (fetch, payload = {}) => {
  const allowedKeys = ['operator'];
  const cleanedQuery = cleanQuery(payload, allowedKeys);
  const graphqlQuery = buildGraphqlQuery(cleanedQuery);

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{ fuelLocations${graphqlQuery} { id slug title } }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.fuelLocations);
};

export const SET_LOCATIONS = 'SET_LOCATIONS';

export const setFuelLocations = payload => ({ type: SET_LOCATIONS, payload });

// export const SET_FILTERED_LOCATIONS = 'SET_FILTERED_LOCATIONS';
//
// export const setFilteredFuelLocations = payload => ({
//   type: SET_FILTERED_LOCATIONS,
//   payload,
// });

export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';

export const setLocationFilter = locationIds => ({
  type: SET_LOCATION_FILTER,
  payload: locationIds,
});

export const SET_FUEL_TYPE_FILTER = 'SET_FUEL_TYPE_FILTER';

export const setFuelTypeFilter = fuelTypeIds => ({
  type: SET_FUEL_TYPE_FILTER,
  payload: fuelTypeIds,
});

export const SET_OPERATOR_FILTER = 'SET_OPERATOR_FILTER';

export const setOperatorFilter = operatorIds => ({
  type: SET_OPERATOR_FILTER,
  payload: operatorIds,
});
