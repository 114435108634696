import { loading } from './runtime';

export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const IMAGE_UPLOAD_FAIL = 'IMAGE_UPLOAD_FAIL';

const UPLOAD_URL = '/px/api/image_container/'; // request to local proxy

export function uploadFail(errors) {
  return {
    type: IMAGE_UPLOAD_FAIL,
    payload: { errors },
    meta: {
      loading: false,
    },
  };
}

export function uploadImages(fetch, formData, successAction, failAction) {
  return dispatch => {
    dispatch(loading());

    fetch(UPLOAD_URL, {
      method: 'post',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        // TODO: Handle errors
        if (data.errors) {
          if (failAction) {
            dispatch(failAction(data.errors));
          } else {
            dispatch(uploadFail(data.errors));
          }
        } else if (successAction) {
          dispatch(successAction(data));
        } else {
          dispatch({
            type: IMAGE_UPLOAD,
            payload: data,
            meta: {
              loading: false,
            },
          });
        }
      })
      .catch(err => {
        if (failAction) {
          dispatch(failAction(err));
        } else {
          dispatch(uploadFail(err));
        }
        throw err;
      });
  };
}
