import { authVerifyRequest } from 'actions/authActions';
import jwt from 'jsonwebtoken';

export const isAuthenticated = async (store, fetch) => {
  const authStatus = store.getState().auth;
  if (authStatus.token) {
    const token = authVerifyRequest(fetch, authStatus.token);
    if (token) {
      return true;
    }
  }
  return false;
};

export default async function loginRequired(store, fetch, next) {
  const redirect = `/login${next ? `?next=${next}` : ''}`;
  const authStatus = await isAuthenticated(store, fetch);
  return !authStatus ? { redirect } : undefined;
}

export const decodeToken = token => {
  try {
    const { user_id: userId } = jwt.decode(token);
    return { userId };
  } catch (err) {
    return null;
  }
};
