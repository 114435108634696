import { TOGGLE_MENU, HIDE_MENU, SHOW_MENU } from '../../actions/app/menu';

export default function menu(state = { show: false }, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return Object.assign({}, state, { show: !state.show });
    case HIDE_MENU:
      return { show: false };
    case SHOW_MENU:
      return { show: true };
    default:
      return state;
  }
}
