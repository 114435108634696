/* eslint-disable import/prefer-default-export */

import { IntlProvider } from 'react-intl';
import URI from 'urijs';
import moment from 'moment';

import {
  SET_LOCALE_START,
  SET_LOCALE_SUCCESS,
  SET_LOCALE_ERROR,
} from '../constants';
import { makeLocaleUrl, cleanLocaleFromUrl, DEFAULT_LOCALE } from '../locales';

// import queryIntl from './intl.graphql';

function getIntlFromState(state) {
  const intl = (state && state.intl) || {};
  const { initialNow, locale, messages } = intl;
  const localeMessages = (messages && messages[locale]) || {};
  const provider = new IntlProvider({
    initialNow,
    locale,
    messages: localeMessages,
    defaultLocale: DEFAULT_LOCALE,
  });

  return provider.getChildContext().intl;
}

export function getIntl() {
  return (dispatch, getState) => getIntlFromState(getState());
}

export async function fethcLocaleData(fetch, locale) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `query intl ($locale:String!) {
        intl (locale:$locale) {
          id
          message
        }
      }`,
      variables: { locale },
    }),
  })
    .then(response => response.json())
    .then(data => data.data.intl);
}

export function setLocale(fetch, { locale }) {
  return async (dispatch, getState) => {
    const {
      intl: { locale: currentLocale },
    } = getState();

    dispatch({
      type: SET_LOCALE_START,
      payload: {
        locale,
      },
    });

    try {
      const intl = await fethcLocaleData(fetch, locale);
      const messages = (intl || []).reduce((msgs, msg) => {
        msgs[msg.id] = msg.message; // eslint-disable-line no-param-reassign
        return msgs;
      }, {});

      dispatch({
        type: SET_LOCALE_SUCCESS,
        payload: {
          locale,
          messages,
        },
      });

      moment.locale(locale);
      // remember locale for every new request
      if (process.env.BROWSER) {
        const maxAge = 3650 * 24 * 3600; // 10 years in seconds
        document.cookie = `lang=${locale};path=/;max-age=${maxAge}`;
        const { pathname, search } = window.location;
        const query = new URI(search).search(true);
        const url = cleanLocaleFromUrl(pathname, currentLocale);
        // TODO: make async locale change
        window.location.href = makeLocaleUrl(
          new URI(url).search(query).toString(),
          locale,
        );
      }
      // return bound intl instance at the end
      return getIntlFromState(getState());
    } catch (error) {
      dispatch({
        type: SET_LOCALE_ERROR,
        payload: {
          locale,
          error,
        },
      });
      return null;
    }
  };
}
