import {
  SET_BLOG_LIST,
  SET_BLOG_ITEM,
  SET_BLOGS_TO_WIDGET,
} from '../actions/blogsActions';

const defaultState = {
  list: {
    query: {},
    data: {
      meta: {
        total_count: 0,
      },
      items: [],
    },
  },
  item: {},
  widgetBlogs: {
    items: [],
  },
};

export default function blog(state = defaultState, action) {
  switch (action.type) {
    case SET_BLOG_LIST:
      return { ...state, list: action.payload };
    case SET_BLOG_ITEM:
      return { ...state, item: action.payload };
    case SET_BLOGS_TO_WIDGET:
      return { ...state, widgetBlogs: action.payload };
    default:
      return state;
  }
}
