import { SET_CITIES, SET_LOCATIONS_TREE } from '../actions/locationActions';

export default function(state = [], action) {
  switch (action.type) {
    case SET_CITIES:
      return action.payload;
    case SET_LOCATIONS_TREE:
      return action.payload;
    default:
      return state;
  }
}
