// import history from '../history';
import { IMAGE_UPLOAD, IMAGE_UPLOAD_FAIL } from '../actions/imagesActions';

export default function create(state = {}, action) {
  switch (action.type) {
    case IMAGE_UPLOAD:
      return action.payload;
    case IMAGE_UPLOAD_FAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
