import {
  SET_ADDFORM_MAKES,
  SET_ADDFORM_MODELS,
  SET_ADDFORM_LOCATIONS,
  SET_ADDFORM_BODY,
  SET_ADDFORM_FUEL,
  SET_ADDFORM_GEARS_TYPE,
  SET_ADDFORM_DRIVE,
  SET_ADDFORM_COLOR,
  SET_ADDFORM_CUSTOMS,
  SET_ADDFORM_KREDIT,
  SET_ADDFORM_OTHER,
} from '../actions/add/formActions';

const defaultState = {
  makes: [],
  models: [],
  locations: [],
  body: [],
  fuel: [],
  gearsType: [],
  drive: [],
  color: [],
  customs: [],
  kredit: [],
  other: [],
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case SET_ADDFORM_MAKES:
      return { ...state, makes: action.payload };
    case SET_ADDFORM_MODELS:
      return { ...state, models: action.payload };
    case SET_ADDFORM_LOCATIONS:
      return { ...state, locations: action.payload };
    case SET_ADDFORM_FUEL:
      return { ...state, fuel: action.payload };
    case SET_ADDFORM_BODY:
      return { ...state, body: action.payload };
    case SET_ADDFORM_GEARS_TYPE:
      return { ...state, gearsType: action.payload };
    case SET_ADDFORM_DRIVE:
      return { ...state, drive: action.payload };
    case SET_ADDFORM_COLOR:
      return { ...state, color: action.payload };
    case SET_ADDFORM_CUSTOMS:
      return { ...state, customs: action.payload };
    case SET_ADDFORM_KREDIT:
      return { ...state, kredit: action.payload };
    case SET_ADDFORM_OTHER:
      return { ...state, other: action.payload };
    default:
      return state;
  }
}
