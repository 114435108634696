import { setSchemaBreadcrumbs } from 'actions/schemaActions';

export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export function setBreadcrumbs(payload) {
  return dispatch => {
    dispatch(setSchemaBreadcrumbs(payload)); // set breadcrumbs for json ld
    dispatch({
      type: SET_BREADCRUMBS,
      payload,
    });
  };
}
