const readyStates = new Set(['complete', 'loaded', 'interactive']);

function loadMainClient() {
  const main = require('./client').default; // eslint-disable-line global-require
  main();
}

function run() {
  // Run the application when both DOM is ready and page content is loaded
  if (readyStates.has(document.readyState) && document.body) {
    loadMainClient();
  } else {
    document.addEventListener('DOMContentLoaded', loadMainClient, false);
  }
}

if (!global.Intl) {
  // You can show loading banner here
  require.ensure(
    ['intl', 'intl/locale-data/jsonp/uk.js', 'intl/locale-data/jsonp/ru.js'],
    require => {
      require('intl');
      // TODO: This is bad. You should only require one language dynamically
      require('intl/locale-data/jsonp/uk');
      require('intl/locale-data/jsonp/ru');
      run();
    },
    // 'polyfills',
  );
} else {
  run();
}
