import { success, error as fail } from 'react-notification-system-redux';

import { buildGraphqlQuery, cleanQuery } from '../../data/utils';
import { loading } from '../runtime';

export const SET_PERSONAL_CAR_OFFERS = 'SET_PERSONAL_CAR_OFFERS';

export const setPersonalCarItems = (cars, query) => ({
  type: SET_PERSONAL_CAR_OFFERS,
  payload: {
    response: cars,
    query,
  },
  meta: {
    loading: false,
  },
});

export async function carPersonalItemsRequest(fetch, query) {
  const allowedKeys = ['page', 'locations', 'make', 'model', 'my', 'active'];
  const graphqlQuery = buildGraphqlQuery(cleanQuery(query, allowedKeys));

  return fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        cars${graphqlQuery} {
          prev
          next
          count
          results {
            id
            title
            model {
              full_title
            }
            fuel {
              title
            }
            gears_type {
              title
            }
            body {
              title
            }
            drive {
              title
            }
            customs {
              title
            }
            color {
              title
            }
            kredit {
              title
            }
            other {
              title
            }
            modification: catalog_prototype {
              title
              generation {
                images {
                  url
                }
              }
            }
            description
            year
            price
            price_currency
            mileage
            active
            updated
            created
            location {
              title
            }
            images {
              url
            }
            services {
              slug
              title
              date_start
              date_end
            }
          }
        }
      }`,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.cars);
}

export function fetchPersonalCars(fetch, query) {
  const myQuery = { ...query, my: true };
  return dispatch => {
    carPersonalItemsRequest(fetch, myQuery).then(cars => {
      dispatch(setPersonalCarItems(cars, query));
    });
  };
}

export const PARTIAL_UPDATE_CAR_FAIL = 'PARTIAL_UPDATE_CAR_FAIL';

export function partialUpdateCarFail(payload) {
  return {
    type: PARTIAL_UPDATE_CAR_FAIL,
    payload,
    meta: {
      loading: false,
    },
  };
}

export const PARTIAL_UPDATE_CAR_SUCCESS = 'PARTIAL_UPDATE_CAR_SUCCESS';

export function partialUpdateCarItem(fetch, car) {
  return dispatch => {
    dispatch(loading());

    fetch('/graphql', {
      body: JSON.stringify({
        query: `mutation Mutation($car:CarItemPartialInputType) {
          partialUpdateCar(car: $car) {
            id
          }
        }`,
        variables: { car },
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          dispatch(partialUpdateCarFail(data.errors));
          dispatch(
            fail({
              // uid: 'once-please', // you can specify your own uid if required
              // title: 'Hey, it\'s good to see you!',
              message: 'Виникла помилка!',
              // position: 'tr',
              // autoDismiss: 1,
            }),
          );
        } else {
          dispatch(
            success({
              // uid: 'once-please', // you can specify your own uid if required
              // title: 'Hey, it\'s good to see you!',
              message: 'Змінено успішно!',
              // position: 'tr',
              // autoDismiss: 1,
            }),
          );
          dispatch({
            type: PARTIAL_UPDATE_CAR_SUCCESS,
            payload: {
              updated: car,
            },
            meta: {
              loading: false,
            },
          });
        }
      });
  };
}
