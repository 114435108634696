import {
  SET_TICKET,
  SET_TICKETS,
  SET_IS_STRICT,
  SET_CURRENT_TEST,
  SET_CURRENT_PART,
  SET_SUBSCRIPTION,
} from '../actions/pdrActions';

const defaultState = {
  ticket: null,
  tickets: [],
  isStrictMode: false,
  pdrTest: null,
  currentPartQuery: null,
  subscription: null,
};

export default function profile(state = defaultState, action) {
  switch (action.type) {
    case SET_TICKET:
      return { ...state, ticket: action.payload };
    case SET_TICKETS:
      return { ...state, tickets: action.payload };
    case SET_IS_STRICT:
      return { ...state, isStrictMode: action.payload };
    case SET_CURRENT_TEST:
      return { ...state, pdrTest: action.payload };
    case SET_CURRENT_PART:
      return { ...state, currentPartQuery: action.payload };
    case SET_SUBSCRIPTION:
      return { ...state, subscription: action.payload };
    default:
      return state;
  }
}
