import { REGISTER_SUCCESS_URL } from 'constants/auth';
import history from '../history';
import {
  AUTH_LOGIN,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REGISTER,
  AUTH_REGISTER_ERROR,
} from '../actions/authActions';

export default function(state = {}, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      if (action.payload.next) {
        history.push(action.payload.next);
      }
      return action.payload;
    case AUTH_ERROR:
      return { ...state, ...action.payload };
    case AUTH_LOGOUT:
      return action.payload;
    case AUTH_REGISTER:
      history.push(action.payload.next || REGISTER_SUCCESS_URL);
      return action.payload;
    case AUTH_REGISTER_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
