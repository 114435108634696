import reject from 'lodash/reject';

import {
  SET_PERSONAL_CAR_OFFERS,
  PARTIAL_UPDATE_CAR_FAIL,
  PARTIAL_UPDATE_CAR_SUCCESS,
} from '../../actions/personal/carsActions';
import { SET_PERSONAL_ORDERED_SERVICES_ITEMS } from '../../actions/personal/orderedServicesActions';

const defaultState = {
  cars: { response: {}, query: {} },
  orderedServices: { response: {}, query: {} },
};

export default function offers(state = defaultState, action) {
  switch (action.type) {
    case SET_PERSONAL_CAR_OFFERS:
      return {
        ...state,
        cars: action.payload,
      };
    case SET_PERSONAL_ORDERED_SERVICES_ITEMS:
      return {
        ...state,
        orderedServices: action.payload,
      };
    case PARTIAL_UPDATE_CAR_SUCCESS:
      return {
        ...state,
        cars: {
          ...state.cars,
          response: {
            ...state.cars.response,
            results: reject(
              state.cars.response.results,
              car => car.id === action.payload.updated.id,
            ),
          },
        },
      };
    case PARTIAL_UPDATE_CAR_FAIL:
      return state;
    default:
      return state;
  }
}
