import each from 'lodash/each';

export const RESOLVE_SEARCH_QUERY = 'RESOLVE_SEARCH_QUERY';

const cleanQuery = query => {
  const cleanedQuery = {};
  each(query, (value, key) => {
    if (value) {
      if (key === 'year') {
        cleanedQuery.year_f = parseInt(query.year, 10);
        cleanedQuery.year_t = parseInt(query.year, 10) + 1;
      } else {
        cleanedQuery[key] = value;
      }
    }
  });
  return cleanedQuery;
};

export function resolveSearchQueryRequest(fetch, query) {
  return fetch('/graphql', {
    body: JSON.stringify({
      query: `mutation Mutation($query:String,$resolve:String) {
        resolveQuery(query: $query, resolve: $resolve) {
              make,model,year,fuel,body,gears_type
        }
      }`,
      variables: { query, resolve: 'id' },
    }),
  })
    .then(response => response.json())
    .then(data => cleanQuery(data.data.resolveQuery));
}

export function resolveSearchQuery(fetch, query) {
  return dispatch => {
    resolveSearchQueryRequest(fetch, query).then(data => {
      dispatch({
        type: RESOLVE_SEARCH_QUERY,
        payload: data,
      });
    });
  };
}
